// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-page-js": () => import("/opt/build/repo/src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-js": () => import("/opt/build/repo/src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-brief-js": () => import("/opt/build/repo/src/pages/brief.js" /* webpackChunkName: "component---src-pages-brief-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logofolio-js": () => import("/opt/build/repo/src/pages/logofolio.js" /* webpackChunkName: "component---src-pages-logofolio-js" */),
  "component---src-pages-process-js": () => import("/opt/build/repo/src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-web-design-js": () => import("/opt/build/repo/src/pages/web-design.js" /* webpackChunkName: "component---src-pages-web-design-js" */)
}

